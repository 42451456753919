import React from 'react'

import Image from 'next/image'
import Link from 'next/link'

const FurtherProducts: React.FC = () => (
  <section className="bg-white px-4 py-16 lg:py-24 text-center">
    <div className="max-w-content-lg w-full mx-auto">
      <h2 className="font-extrabold w-full leading-snug text-center text-xl md:text-2xl xl:text-4xl text-black px-8 mb-8 md:mb-16 lg:mb-16 xl:mb-20 z-10">
        Alle Neuheiten und Infos
      </h2>

      <div className="gap-12 grid grid-cols-1 md:grid-cols-2 w-full mx-auto text-black">
        <div className="mx-auto flex justify-center items-center">
          <div>
            <Image
              alt="Smartphones mit Instagram und Facebook."
              height={490}
              src="/images/socialmedia-phone.jpg"
              width={400}
              objectFit="contain"
            />
          </div>
        </div>
        <div className="flex flex-wrap md:pr-16">
          <div className=" mx-auto flex flex-wrap justify-center">
            <div className="p-4 w-1/2 hover:cursor-pointer">
              <Link href="https://www.facebook.com/hydeep-100245629112234/" passHref>
                <a href="/" target="_blank" rel="noreferrer" title="zur hydeep Facebook Seite">
                  <div>
                    <svg
                      className="mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 60.5108 60.9114"
                      width="60"
                      height="60">
                      <g id="Ebene_2" data-name="Ebene 2">
                        <g id="Ebene_1-2" data-name="Ebene 1">
                          <path
                            d="M40.5656,35.3739a35.8436,35.8436,0,0,0,6.0108,0,22.7067,22.7067,0,0,1,.3091-3.0134c.131-.4643.385-1.9777.4665-2.4532a23.3458,23.3458,0,0,0,.0994-3.0634h-7.645V21.83a2.0416,2.0416,0,0,1,2.127-2.322h5.395a21.664,21.664,0,0,1,0-5.1786,17.8642,17.8642,0,0,0,0-3.0994l-7.43-.029c-8.248,0-9.06,5.66-10.125,10.125v5.518l-4.77.4028V34.74a.6338.6338,0,0,0,.6338.6338h3.3954a.7348.7348,0,0,1,.7389.7362c-.0226,4.6628-.2415,9.7818.0019,14.1733a87.6972,87.6972,0,0,1,0,10.11s-7.5223-.2844-16.34-.1985C6.5071,60.2624.4515,54.5659.8917,47.6534,1.2446,42.11.4173,35.3624.5068,29.7077c.1737-10.9612.0454-10.6758.3849-16.09.4695-7.49,3.9374-12.2789,10.213-12.44,0,0,10.55.2958,17.86-.1735C39.8641.3038,44.8432.372,49.1118.97c7.0458.9867,10.899,5.7211,10.899,12.6476v9.9171c0,3.3027-.113,10.3733-.11,13.7614.0023,2.648.11,10.3575.11,10.3575,0,3.31-1.6478,5.59-3.7431,7.8076-2.2892,2.4224-5.1833,4.6359-8.7985,4.734-2.5725.07-5.68.1377-7.7136.1985,0,0-.552-13.4409.01-24.2685A.7953.7953,0,0,1,40.5656,35.3739Z"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2.001"
                          />
                        </g>
                      </g>
                    </svg>

                    <h3 className="mt-4 mb-2 font-semibold">hydeep auf Facebook</h3>
                  </div>
                </a>
              </Link>
            </div>
            <div className="p-4 w-1/2 hover:cursor-pointer">
              <Link href="https://www.instagram.com/hydeep_skincare/" passHref>
                <a href="/" target="_blank" rel="noreferrer" title="zur hydeep Instagram Seite">
                  <div>
                    <svg
                      className="mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 61.1357 60.6513"
                      width="60"
                      height="60">
                      <g id="Ebene_2" data-name="Ebene 2">
                        <g id="Ebene_1-2" data-name="Ebene 1">
                          <path
                            d="M47.6534,59.6191c-5.5432-.3529-12.2608.9585-17.9457.3849-10.9072-1.1-10.7373.4969-16.09-.3849C4.929,58.188,1.0758,54.0041,1.0758,47.0776c0,0,.397-8.2214-.0723-15.5318C.3038,20.6467.372,15.6676.97,11.3991,1.9565,4.3532,6.6909.5,13.6174.5h9.9171c3.3027,0,10.3733.113,13.7614.11C39.9439.6078,47.6534.5,47.6534.5c3.31,0,5.59,1.6479,7.8076,3.7431,2.4224,2.2893,4.6359,5.1833,4.734,8.7985.2359,8.6991.4508,13.8769.44,18.5042-.0088,3.8845-.44,7.0459-.44,15.5318C60.195,54.0041,54.5659,60.0593,47.6534,59.6191Z"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2.001"
                          />
                          <circle
                            cx="30.5678"
                            cy="31.2915"
                            r="16.1075"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2.001"
                          />
                          <path
                            d="M49.2576,12.94c0,1.3985-1.1337,3.0826-2.5321,3.0826s-1.8144-.7706-2.5321-2.5321c-.5277-1.2951,1.1336-2.5321,2.5321-2.5321S49.2576,11.5419,49.2576,12.94Z"
                            fill="currentColor"
                            stroke="currentColor"
                            strokeWidth="2.001"
                          />
                        </g>
                      </g>
                    </svg>
                    <h3 className="mt-4 mb-2 font-semibold">hydeep auf Instagram</h3>
                  </div>
                </a>
              </Link>
            </div>
            <div className="p-4 w-1/2 hover:cursor-pointer">
              <Link href="https://www.facebook.com/thegreenestgreens" passHref>
                <a
                  href="/"
                  target="_blank"
                  rel="noreferrer"
                  title="zur The greenest greens Facebook Seite">
                  <div>
                    <svg
                      className="mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 60.5108 60.9114"
                      width="60"
                      height="60">
                      <g id="Ebene_2" data-name="Ebene 2">
                        <g id="Ebene_1-2" data-name="Ebene 1">
                          <path
                            d="M40.5656,35.3739a35.8436,35.8436,0,0,0,6.0108,0,22.7067,22.7067,0,0,1,.3091-3.0134c.131-.4643.385-1.9777.4665-2.4532a23.3458,23.3458,0,0,0,.0994-3.0634h-7.645V21.83a2.0416,2.0416,0,0,1,2.127-2.322h5.395a21.664,21.664,0,0,1,0-5.1786,17.8642,17.8642,0,0,0,0-3.0994l-7.43-.029c-8.248,0-9.06,5.66-10.125,10.125v5.518l-4.77.4028V34.74a.6338.6338,0,0,0,.6338.6338h3.3954a.7348.7348,0,0,1,.7389.7362c-.0226,4.6628-.2415,9.7818.0019,14.1733a87.6972,87.6972,0,0,1,0,10.11s-7.5223-.2844-16.34-.1985C6.5071,60.2624.4515,54.5659.8917,47.6534,1.2446,42.11.4173,35.3624.5068,29.7077c.1737-10.9612.0454-10.6758.3849-16.09.4695-7.49,3.9374-12.2789,10.213-12.44,0,0,10.55.2958,17.86-.1735C39.8641.3038,44.8432.372,49.1118.97c7.0458.9867,10.899,5.7211,10.899,12.6476v9.9171c0,3.3027-.113,10.3733-.11,13.7614.0023,2.648.11,10.3575.11,10.3575,0,3.31-1.6478,5.59-3.7431,7.8076-2.2892,2.4224-5.1833,4.6359-8.7985,4.734-2.5725.07-5.68.1377-7.7136.1985,0,0-.552-13.4409.01-24.2685A.7953.7953,0,0,1,40.5656,35.3739Z"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2.001"
                          />
                        </g>
                      </g>
                    </svg>

                    <h3 className="mt-4 mb-2 font-semibold">The greenest greens auf Facebook</h3>
                  </div>
                </a>
              </Link>
            </div>
            <div className="p-4 w-1/2 hover:cursor-pointer">
              <Link href="https://www.instagram.com/thegreenestgreens/" passHref>
                <a
                  href="/"
                  target="_blank"
                  rel="noreferrer"
                  title="zur The greenest greens Instagram Seite">
                  <div>
                    <svg
                      className="mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 61.1357 60.6513"
                      width="60"
                      height="60">
                      <g id="Ebene_2" data-name="Ebene 2">
                        <g id="Ebene_1-2" data-name="Ebene 1">
                          <path
                            d="M47.6534,59.6191c-5.5432-.3529-12.2608.9585-17.9457.3849-10.9072-1.1-10.7373.4969-16.09-.3849C4.929,58.188,1.0758,54.0041,1.0758,47.0776c0,0,.397-8.2214-.0723-15.5318C.3038,20.6467.372,15.6676.97,11.3991,1.9565,4.3532,6.6909.5,13.6174.5h9.9171c3.3027,0,10.3733.113,13.7614.11C39.9439.6078,47.6534.5,47.6534.5c3.31,0,5.59,1.6479,7.8076,3.7431,2.4224,2.2893,4.6359,5.1833,4.734,8.7985.2359,8.6991.4508,13.8769.44,18.5042-.0088,3.8845-.44,7.0459-.44,15.5318C60.195,54.0041,54.5659,60.0593,47.6534,59.6191Z"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2.001"
                          />
                          <circle
                            cx="30.5678"
                            cy="31.2915"
                            r="16.1075"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2.001"
                          />
                          <path
                            d="M49.2576,12.94c0,1.3985-1.1337,3.0826-2.5321,3.0826s-1.8144-.7706-2.5321-2.5321c-.5277-1.2951,1.1336-2.5321,2.5321-2.5321S49.2576,11.5419,49.2576,12.94Z"
                            fill="currentColor"
                            stroke="currentColor"
                            strokeWidth="2.001"
                          />
                        </g>
                      </g>
                    </svg>

                    <h3 className="mt-4 mb-2 font-semibold">The greenest greens auf Instagram</h3>
                  </div>
                </a>
              </Link>
            </div>

            <div className="p-4 w-1/2 hover:cursor-pointer">
              <Link href="https://www.facebook.com/Urban-Eden-108869998282945" passHref>
                <a href="/" target="_blank" rel="noreferrer" title="zur Urban Eden Facebook Seite">
                  <div>
                    <svg
                      className="mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 60.5108 60.9114"
                      width="60"
                      height="60">
                      <g id="Ebene_2" data-name="Ebene 2">
                        <g id="Ebene_1-2" data-name="Ebene 1">
                          <path
                            d="M40.5656,35.3739a35.8436,35.8436,0,0,0,6.0108,0,22.7067,22.7067,0,0,1,.3091-3.0134c.131-.4643.385-1.9777.4665-2.4532a23.3458,23.3458,0,0,0,.0994-3.0634h-7.645V21.83a2.0416,2.0416,0,0,1,2.127-2.322h5.395a21.664,21.664,0,0,1,0-5.1786,17.8642,17.8642,0,0,0,0-3.0994l-7.43-.029c-8.248,0-9.06,5.66-10.125,10.125v5.518l-4.77.4028V34.74a.6338.6338,0,0,0,.6338.6338h3.3954a.7348.7348,0,0,1,.7389.7362c-.0226,4.6628-.2415,9.7818.0019,14.1733a87.6972,87.6972,0,0,1,0,10.11s-7.5223-.2844-16.34-.1985C6.5071,60.2624.4515,54.5659.8917,47.6534,1.2446,42.11.4173,35.3624.5068,29.7077c.1737-10.9612.0454-10.6758.3849-16.09.4695-7.49,3.9374-12.2789,10.213-12.44,0,0,10.55.2958,17.86-.1735C39.8641.3038,44.8432.372,49.1118.97c7.0458.9867,10.899,5.7211,10.899,12.6476v9.9171c0,3.3027-.113,10.3733-.11,13.7614.0023,2.648.11,10.3575.11,10.3575,0,3.31-1.6478,5.59-3.7431,7.8076-2.2892,2.4224-5.1833,4.6359-8.7985,4.734-2.5725.07-5.68.1377-7.7136.1985,0,0-.552-13.4409.01-24.2685A.7953.7953,0,0,1,40.5656,35.3739Z"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2.001"
                          />
                        </g>
                      </g>
                    </svg>

                    <h3 className="mt-4 mb-2 font-semibold">Urban Eden auf Facebook</h3>
                  </div>
                </a>
              </Link>
            </div>
            <div className="p-4 w-1/2 hover:cursor-pointer">
              <Link href="https://www.instagram.com/urbaneden_yoself/" passHref>
                <a href="/" target="_blank" rel="noreferrer" title="zur Urban Eden Instagram Seite">
                  <div>
                    <svg
                      className="mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 61.1357 60.6513"
                      width="60"
                      height="60">
                      <g id="Ebene_2" data-name="Ebene 2">
                        <g id="Ebene_1-2" data-name="Ebene 1">
                          <path
                            d="M47.6534,59.6191c-5.5432-.3529-12.2608.9585-17.9457.3849-10.9072-1.1-10.7373.4969-16.09-.3849C4.929,58.188,1.0758,54.0041,1.0758,47.0776c0,0,.397-8.2214-.0723-15.5318C.3038,20.6467.372,15.6676.97,11.3991,1.9565,4.3532,6.6909.5,13.6174.5h9.9171c3.3027,0,10.3733.113,13.7614.11C39.9439.6078,47.6534.5,47.6534.5c3.31,0,5.59,1.6479,7.8076,3.7431,2.4224,2.2893,4.6359,5.1833,4.734,8.7985.2359,8.6991.4508,13.8769.44,18.5042-.0088,3.8845-.44,7.0459-.44,15.5318C60.195,54.0041,54.5659,60.0593,47.6534,59.6191Z"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2.001"
                          />
                          <circle
                            cx="30.5678"
                            cy="31.2915"
                            r="16.1075"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2.001"
                          />
                          <path
                            d="M49.2576,12.94c0,1.3985-1.1337,3.0826-2.5321,3.0826s-1.8144-.7706-2.5321-2.5321c-.5277-1.2951,1.1336-2.5321,2.5321-2.5321S49.2576,11.5419,49.2576,12.94Z"
                            fill="currentColor"
                            stroke="currentColor"
                            strokeWidth="2.001"
                          />
                        </g>
                      </g>
                    </svg>

                    <h3 className="mt-4 mb-2 font-semibold">Urban Eden auf Instagram</h3>
                  </div>
                </a>
              </Link>
            </div>
            <div className="p-4 w-1/2 hover:cursor-pointer">
              <Link href="https://www.instagram.com/yoself_official/" passHref>
                <a href="/" target="_blank" rel="noreferrer" title="zur yoself Instagram Seite">
                  <div>
                    <svg
                      className="mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 61.1357 60.6513"
                      width="60"
                      height="60">
                      <g id="Ebene_2" data-name="Ebene 2">
                        <g id="Ebene_1-2" data-name="Ebene 1">
                          <path
                            d="M47.6534,59.6191c-5.5432-.3529-12.2608.9585-17.9457.3849-10.9072-1.1-10.7373.4969-16.09-.3849C4.929,58.188,1.0758,54.0041,1.0758,47.0776c0,0,.397-8.2214-.0723-15.5318C.3038,20.6467.372,15.6676.97,11.3991,1.9565,4.3532,6.6909.5,13.6174.5h9.9171c3.3027,0,10.3733.113,13.7614.11C39.9439.6078,47.6534.5,47.6534.5c3.31,0,5.59,1.6479,7.8076,3.7431,2.4224,2.2893,4.6359,5.1833,4.734,8.7985.2359,8.6991.4508,13.8769.44,18.5042-.0088,3.8845-.44,7.0459-.44,15.5318C60.195,54.0041,54.5659,60.0593,47.6534,59.6191Z"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2.001"
                          />
                          <circle
                            cx="30.5678"
                            cy="31.2915"
                            r="16.1075"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2.001"
                          />
                          <path
                            d="M49.2576,12.94c0,1.3985-1.1337,3.0826-2.5321,3.0826s-1.8144-.7706-2.5321-2.5321c-.5277-1.2951,1.1336-2.5321,2.5321-2.5321S49.2576,11.5419,49.2576,12.94Z"
                            fill="currentColor"
                            stroke="currentColor"
                            strokeWidth="2.001"
                          />
                        </g>
                      </g>
                    </svg>

                    <h3 className="mt-4 mb-2 font-semibold">yoself auf Instagram</h3>
                  </div>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default FurtherProducts
